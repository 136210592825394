.video {
  min-height: 100vh;
  min-width: 100vw;
  position: relative;
  overflow: hidden;
  color: rgb(15, 14, 13);
  z-index: -1;

  &__container {
    flex-direction: column;

    @media screen and (min-width: 500px) {
      flex-direction: row;
    }

    h2 {
      font-size: clamp(14px, 2vw, 1rem);
      color: white;
      max-width: clamp(250px, 25vw, 500vw);
      filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
      text-align: justify;
      margin-top: 20px;
      @media screen and (min-width: 500px) {
        font-weight: 700;
        letter-spacing: 1.2px;
        line-height: 25px;
      }
    }

    .playbtn {
      width: 70px;
      height: 45px;
      border-radius: 10px;
      background-color: rgba(205, 60, 60, 0.7);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      .white {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 15px solid rgb(255, 255, 255);
        cursor: pointer;
      }
    }
  }
  &__thumnail {
    width: clamp(220px, 30vw, 500px);
    border: 10px solid rgba(205, 60, 60, 1);
    border-radius: 5px;
  }
  .bg {
    width: clamp(280px, 32vw, 600px);
    height: clamp(200px, 25vw, 400px);
    position: absolute;
    background-color: white;
    background-color: lighten(#20feb1, 20%);
    z-index: -1;
    filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
  }

  .play-btn {
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
    justify-content: space-between;
    border: 4px solid black;
    width: 230px;
    height: 45px;
    position: relative;
    cursor: pointer;
    text-transform: capitalize;
    font-weight: 700;
    margin-top: 30px;
    border-radius: 5px;

    .arrow-icon {
      background-color: #000000;
      width: 50px;
      height: 100%;
      color: white;
      transition: 0.3s;
      position: absolute;
      right: 0;
    }

    &:hover {
      .arrow-icon {
        width: 100%;
      }
    }
  }
}
