.header {
  min-height: 100vh;
  background-color: #ecf3f3;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 200px 15px 10px 30px;

  .title {
    h1 {
      color: #207efe;
    }
  }
  @media only screen and (min-width: 500px) {
    justify-content: center;
    align-items: center;
  }

  .profile-image {
    width: clamp(220px, 50vw, 600px);
    filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.3)) blur(2px)
      hue-rotate(60deg);
  }

  .text-position {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-30%) translateX(10%);
    h1 {
      font-size: 60px;
      line-height: 50px;
      @media only screen and (min-width: 500px) {
        font-size: clamp(60px, 20vw, 80px);
        line-height: clamp(3.5rem, 6vw, 4.2rem);
      }
      @media only screen and (min-width: 700px) {
        font-size: clamp(60px, 25vw, 90px);
        line-height: clamp(3.5rem, 6vw, 4.2rem);
      }
    }
    h1:nth-child(2) {
      transform: translateX(50%);
    }

    @media only screen and (min-width: 500px) {
      transform: translateY(clamp(-250px, 30vw, -200px)) translateX(10%);
    }
    @media only screen and (min-width: 700px) {
      transform: translateY(clamp(-250px, 30vw, -350px)) translateX(50%);
    }
  }

  .my-bio {
    background-color: white;
    margin-left: 40px;
    padding: 15px;
    transform: translateY(-25%);
    border-radius: 3px;
    font-family: 'Roboto Mono', monospace;
    font-size: 12px;
    line-height: 20px;
    word-spacing: 1px;

    @media only screen and (min-width: 700px) {
      background-color: transparent;
      margin: 0;
      width: 300px;
      transform: translateY(-55%) translateX(-60%);
      font-size: 15px;
      line-height: 25px;
      word-spacing: 1.5px;
    }
    @media only screen and (min-width: 900px) {
      transform: translateY(-55%) translateX(-100%);
      font-size: 16px;
    }
    p {
      font-weight: 300;
      text-align: justify;
    }

    span {
      display: block;
      font-weight: 700;
      font-size: 14px;

      @media only screen and (min-width: 700px) {
        font-size: 16px;
      }
      @media only screen and (min-width: 900px) {
        font-size: 18px;
      }
    }
  }
}
