.skills {
  min-height: 100vh;
  position: relative;

  .title {
    h1 {
      color: #ff22ed;
    }
  }
  &__canvas {
    min-height: clamp(320px, 55vh, 600px);

    @media only screen and (min-width: 700px) {
      min-height: clamp(520px, 75vh, 800px);
    }
  }

  .title-position {
    position: absolute;
  }
}
