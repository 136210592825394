.box {
  flex-shrink: 0;
  width: 100vw;
  height: 100vh;
  position: relative;
  border: 10px solid white;
  overflow: hidden;
  cursor: none;

  .bg {
    position: absolute;
    inset: 0;
    // background-color: #0f0e0d;
  }

  .project {
    flex-direction: column;
  }

  .project h1 {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    color: transparent;
    font-size: clamp(30px, 8vw, 60px);
    letter-spacing: 5px;
    font-weight: 400;
    transition: color 300ms;
    position: relative;
    display: inline-block;
    z-index: 65;
    margin-bottom: 15px;

    @media screen and (min-width: 700px) {
      font-size: clamp(40px, 12vw, 80px);
    }
  }
  .project h1:hover {
    color: white;
  }
  .project p {
    font-size: clamp(13px, 1vw, 16px);
    z-index: 65;
    max-width: 280px;
    color: white;
    text-align: justify;
    @media only screen and (min-width: 500px) {
      max-width: clamp(400px, 10vw, 600vw);
      color: rgba(177, 166, 166, 0.801);
    }
    @media only screen and (min-width: 700px) {
      max-width: clamp(550px, 30vw, 800vw);
      color: rgba(177, 166, 166, 0.801);
    }
  }

  .outer-cursor {
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 50;
    border: 1px solid white;
    border-radius: 50%;
    transition: 0.2s;
  }

  .product-image-mobile {
    width: 280px;
    height: 150px;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.2s;
    object-fit: cover;

    @media only screen and (min-width: 500px) {
      display: none;
    }
    &.mobile {
      width: 250px;
      height: 500px;
    }
  }

  .product-image {
    display: none;
    transform: translate(-50%, -50%);
    object-fit: cover;
    @media only screen and (min-width: 500px) {
      display: flex;
      width: 550px;
      height: 300px;
      position: absolute;
    }
    &.mobile {
      width: 350px;
      height: 800px;
    }
  }

  .image-container {
    position: relative;
    width: calc(clamp(40px, 15vw, 100px) * 5);
    height: 400px;

    @media only screen and (min-width: 500px) {
      display: none;
    }
    .bg-image {
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      inset: 0;
    }
  }

  .tags {
    display: none;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 30px;

    @media screen and (min-width: 500px) {
      display: flex;
    }
    .tag {
      padding: 5px 10px;
      border: 2px solid black;
      background-color: transparent;
      border-radius: 5px;
      position: relative;
      color: black;
      z-index: 0;

      &::after {
        content: '';
        position: absolute;
        inset: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        background-color: rgb(0, 0, 0);
        z-index: -1;
        transform: scaleX(0);
        transition: 0.2s;
        transform-origin: left;
      }
    }
  }
}

.link {
  margin-top: 30px;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.499);
  font-weight: 700;
  color: rgb(198, 198, 198);

  a {
    color: white;
    text-decoration: none;
    margin-left: 10px;
    cursor: pointer;
  }
}
