.contact {
  min-height: 100vh;

  .white-bg {
    background-color: white;
    padding: 20px;
    box-shadow: 0.1px 0.1px 10px 2px rgba(0, 0, 0, 0.2);
    width: clamp(220px, 30vw, 500px);
    position: relative;

    @media screen and (min-width: 700px) {
      width: clamp(320px, 40vw, 600px);
    }
    .title {
      position: absolute;
      top: 0px;
      transform: translateY(-50%);
      h1 {
        color: #f5ef2f;
      }
    }
    .contact-icons {
      width: 100%;
      flex-direction: column;

      @media screen and (min-width: 700px) {
        flex-direction: row;
      }

      .contact-icon {
        cursor: pointer;
        margin: 15px 0;
        font-size: 26px;

        @media screen and (min-width: 500px) {
          margin: 0 20px;
        }

        &:hover {
          color: #f5ef2f;
        }
      }

      @media screen and (min-width: 500px) {
        min-height: 300px;
      }
    }
  }
}
