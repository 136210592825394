@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,500;0,700;1,300&display=swap');
@import url('//db.onlinewebfonts.com/c/e9b42171fcb09a01f132fb49ecf12041?family=KinfolkPro_Rough');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --main-color: #20feb1;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background: rgb(15, 14, 13);
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: var(--main-color);
  border-radius: 100px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  background-color: #ecf3f3;
}

.scroll-carousel {
  background-color: #fff;
}

.sticky-box {
  position: sticky;
  top: 0;
  overflow: hidden;
}

.transform-box {
  display: flex;
  will-change: transform;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  font-family: 'Cedarville Cursive', cursive;
  // font-family: 'KinfolkPro_Rough', cursive;
  font-size: clamp(1.5rem, 4vw, 6rem);
  -webkit-user-select: none;

  h1 {
    line-height: clamp(2.2rem, 7vw, 7.2rem);
    color: var(--main-color);
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
