.project-form {
  font-family: 'Roboto Mono', monospace;

  .form-input-field {
    margin: 20px;
  }

  .single-input-field {
    width: 100%;
    height: 45px;
    outline: none;
    padding: 0 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 2px 2px 4px rgba(114, 142, 171, 0.1),
      -6px -6px 20px rgba(255, 255, 255, 0.6),
      4px 4px 20px rgba(111, 140, 176, 0.3);
    margin-top: 5px;
  }
  .single-input-text-area {
    width: 100%;
    outline: none;
    padding: 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 2px 2px 4px rgba(114, 142, 171, 0.1),
      -6px -6px 20px rgba(255, 255, 255, 0.6),
      4px 4px 20px rgba(111, 140, 176, 0.3);
  }
  .ant-upload-picture-card-wrapper {
    // border: 1px solid red;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgba(114, 142, 171, 0.1),
      -6px -6px 20px rgba(255, 255, 255, 0.6),
      4px 4px 20px rgba(111, 140, 176, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ant-upload {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .ant-form-item-required {
    font-size: 18px;
  }
  .ant-form-item-explain-error {
    color: red;
    font-size: 12px;
  }

  .button {
    width: 100%;
    height: 45px;
    background-color: transparent;
    outline: none;
    padding: 0 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 2px 2px 4px rgba(114, 142, 171, 0.1),
      -6px -6px 20px rgba(255, 255, 255, 0.6),
      4px 4px 20px rgba(111, 140, 176, 0.3);
    margin-top: 5px;
    cursor: pointer;
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    .tag {
      padding: 5px 10px;
      border: 2px solid black;
      background-color: transparent;
      cursor: pointer;
      border-radius: 5px;
      position: relative;
      color: black;
      z-index: 0;

      &::after {
        content: '';
        position: absolute;
        inset: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        background-color: rgb(0, 0, 0);
        z-index: -1;
        transform: scaleX(0);
        transition: 0.2s;
        transform-origin: left;
      }
      &:hover {
        color: white;
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }
}
